<template>
  <div class="card p-3">
    <h2 class="text-xl font-semibold">Chưa được cập nhật lời</h2>
    <div class="flex justify-between flex-col-reverse lg:flex-row">
      <nav class="responsive-nav is_ligh -mb-0.5 border-transparent">
        <ul uk-switcher="connect: #emptyLyricSongs-tab; animation: uk-animation-fade">
          <li><a href="#">Tân nhạc <span>{{ thousandFormat(emptyLyricSongs.paginatorInfo.total) }}</span></a></li>
          <li><a href="#">Dân ca <span>{{ thousandFormat(emptyLyricFolks.paginatorInfo.total) }}</span></a></li>
          <li><a href="#">Thành viên hát <span>{{ thousandFormat(emptyLyricKaraokes.paginatorInfo.total) }}</span></a></li>
          <li><a href="#">Tiếng thơ <span>{{ thousandFormat(emptyLyricPoems.paginatorInfo.total) }}</span></a></li>
        </ul>
      </nav>
    </div>

    <div class="uk-switcher" id="emptyLyricSongs-tab">
      <div>
        <div class="mt-4" v-if="emptyLyricSongs.data.length">
          <song-item-upload v-for="song in emptyLyricSongs.data" :song="song" prefix="bai-hat"
                            :key="'song' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="emptyLyricSongs.paginatorInfo.lastPage > 1"
              v-model="emptyLyricSongs.paginatorInfo.currentPage"
              :pages="emptyLyricSongs.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyLyricSongs"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="emptyLyricFolks.data.length">
          <song-item-upload v-for="song in emptyLyricFolks.data" :song="song" prefix="dan-ca"
                            :key="'folk' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="emptyLyricFolks.paginatorInfo.lastPage > 1"
              v-model="emptyLyricFolks.paginatorInfo.currentPage"
              :pages="emptyLyricFolks.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyLyricFolks"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="emptyLyricKaraokes.data.length">
          <song-item-upload v-for="song in emptyLyricKaraokes.data" :song="song" prefix="thanh-vien-hat"
                            :key="'karaoke' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="emptyLyricKaraokes.paginatorInfo.lastPage > 1"
              v-model="emptyLyricKaraokes.paginatorInfo.currentPage"
              :pages="emptyLyricKaraokes.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyLyricKaraokes"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="emptyLyricPoems.data.length">
          <song-item-upload v-for="song in emptyLyricPoems.data" :song="song" prefix="tieng-tho"
                            :key="'poem' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="emptyLyricPoems.paginatorInfo.lastPage > 1"
              v-model="emptyLyricPoems.paginatorInfo.currentPage"
              :pages="emptyLyricPoems.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyLyricPoems"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SongItemUpload from "../song/SongItemUpload";
import ApiService from "../../core/services/api.service";
import {
  thousandFormat
} from "../../core/services/utils.service";

export default {
  name: "EmptyLyricBox",
  components: {SongItemUpload},
  data() {
    return {
      emptyLyricSongs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyLyricFolks: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyLyricKaraokes: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyLyricPoems: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadEmptyLyricSongs() {
      let query = `query($page: Int) {
        songs(first: 10, page: $page, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: NOT_LIKE, value: "Bài ca đi cùng năm tháng%"}, {OR: [{column: "content", value: ""}, {column: "content", value: null}]}]}) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.emptyLyricSongs.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.songs) {
              this.emptyLyricSongs = data.data.songs;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyLyricFolks() {
      let query = `query($page: Int) {
        folks(first: 10, page: $page, orderBy: [{column: "views", order: DESC}], where: {OR: [{column: "content", value: ""}, {column: "content", value: null}]}) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.emptyLyricFolks.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.folks) {
              this.emptyLyricFolks = data.data.folks;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyLyricKaraokes() {
      let query = `query($page: Int) {
        karaokes(first: 10, page: $page, orderBy: [{column: "views", order: DESC}], where: {OR: [{column: "content", value: ""}, {column: "content", value: null}]}) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            users(first: 10) {
              data {
                id
                username
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.emptyLyricKaraokes.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.karaokes) {
              this.emptyLyricKaraokes = data.data.karaokes;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyLyricPoems() {
      let query = `query($page: Int) {
        poems(first: 10, page: $page, orderBy: [{column: "views", order: DESC}], where: {OR: [{column: "content", value: ""}, {column: "content", value: null}]}) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.emptyLyricPoems.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.poems) {
              this.emptyLyricPoems = data.data.poems;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
  },
  mounted() {
    this.loadEmptyLyricSongs();
    this.loadEmptyLyricFolks();
    this.loadEmptyLyricKaraokes();
    this.loadEmptyLyricPoems();
  }
}
</script>
