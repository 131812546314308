<template>
  <div class="">
    <empty-lyric-box/>
  </div>
</template>

<script>
import EmptyLyricBox from "./EmptyLyricBox.vue";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: "EmptyLyric",
  components: {EmptyLyricBox},
  mounted() {
    changePageTitle("Cần bạn trợ giúp: Lời bài hát");
  }
}
</script>
